import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Layout from "../../ui/Layout";
import { LoginContext } from "./LoginProvider";
import { BASE_URL } from "../../utils/utils";
import useMobileCheck from "../../hooks/useMobileCheck";

import instruction from "../../translations/instructions.json";

const Modal = () => {
  const { handleMobileAppModalAction } = useContext(LoginContext);
  const [showInstructions] = useState(false);

  const showAppContent = !showInstructions;
  const [modalData, setmodalData] = useState({
    branches: [],
    login_key: "",
  });

  const handleRedirectToApp = (action) => {
    handleMobileAppModalAction(action, modalData);
  };

  const isMobileOrTablet = useMobileCheck();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const url = new URL(`${BASE_URL}/session`);
        const response = await fetch(url, {
          method: "GET",
          credentials: "include", // Use GET method
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch session data");
        }
        const data = await response.json();
        setmodalData({
          branches: data.branches || [],
          login_key: data.login_key || "",
        }); // Update session data state
      } catch (error) {
        navigate("/");
        console.error("Error fetching session data:", error);
      }
    };

    // Call the fetchSessionData function when component mounts
    fetchSessionData();
  }, [navigate, isMobileOrTablet]);

  if (!isMobileOrTablet) {
    navigate("/");
  }

  return (
    <Layout>
      <div>
        {showAppContent && (
          <p className="mb-3 py-1">
            Wir empfehlen Dir unsere mobile App zu nutzen! <br/><br/>
            Wenn Du unsere App bereits installiert hast, klick auf "App öffnen". <br/><br/>
            Falls Du unsere App noch nicht installiert hast, <br/><br/>
            <Link
              className="text-blue-light underline mb-5"
              to="/instruction"
            >
              ({instruction.buttons.link})
            </Link>
          </p>
        )}

        {/* Buttons to open the app or continue in the browser */}
        {showAppContent && (
          <div className="mt-8">
            <button
              onClick={() => handleRedirectToApp("redirect-to-app")}
              className="bg-blue-light text-white px-4 py-2 mr-2 rounded hover:bg-blue-600 mb-5"
            >
              {instruction.buttons.openApp}
            </button>
            <button
              onClick={() => handleRedirectToApp("continue-in-browser")}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
            >
              {instruction.buttons.continueInBrowser}
            </button>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Modal;
